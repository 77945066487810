body {
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  vertical-align: top;
}

input {
  height:100%;
}

div.ruleRow {
  display: block;
  text-indent: -19ex;
  padding-left: 19ex;
}

div.ruleName {
  display: inline;
}

div.ruleName input {
  width: 15ex;
  margin-left: 5px;
}

div.ruleName input {
  width: 15ex;
  margin-left: 5px;
}


div.ruleValue {
  display: inline;
}

div.ruleValue textarea {
  width: 90%;
}

div.sample {
  margin-top: 20px;
  font-size: 20px;
  border-bottom: solid black 1px;
}

div.selected {
  background-color: #FFA;
}

div.sample:last-child {
  border-bottom: none;
}

div#titleBar {
  position: absolute;
  overflow: none;
  border-bottom: solid black 1px;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 25px;
  padding: 1.5px;
}

#titleBar button {
  height: 22px;
  float: right;
}

div#grammarEditor {
  position: absolute;
  left: 0px;
  top: 30px;
  bottom: 0px;
  right: 50%;
  padding: 5px;
  padding-bottom: 100%;
  overflow: auto;
  border-right: solid black 1px;
}

div#outputPanel {
  position: absolute;
  left: 50%;
  top: 30px;
  bottom: 0px;
  right: 0px;
  padding: 5px;
  overflow: auto;
}

input.highlight {
  background-color: #FFA;
}

textarea.highlight {
  background-color: #FFA;
}

#errors, .error {
  color: #FF0000;
}

#errors div::before {
  content: "ERROR: ";
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  background-color: rgba(255,255,255,0.9); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#overlay #text{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  color: black;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

table.pmeta {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

table.pmeta tr td {
  border: solid black 1px;
  width: 25%;
  padding: 5px;
}
table.pmeta tr td:nth-child(3) {
  width: 40%;
}
table.pmeta tr td:nth-child(4) {
  width: 10%;
}

table.pmeta input {
  width: 100%;
}

table.pmeta textarea {
  width: 100%;
}

#titleBar button.dropbtn {
  cursor: pointer;
  height: 22px;
  float: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  float: right;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

#titleBar .dropdown-content button {
  float: none;
  color: black;
  padding: 12px 16px;
  margin: 0;
  text-decoration: none;
  border: none;
  cursor: pointer;
  display: block;
  width: 100%;
  height: auto;
}

.dropdown-content button:hover {background-color: #ccc; }

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  border: solid black 2px;
}

button.linklike {
  margin: 0;
  padding: 0;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  display: inline;
  height: auto;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  color: -webkit-link;
}

.ruleLabel:first-child {
  display: none;
}

.ruleLabel {
  font-size: 65%;
  display: block;
  height: 0;
  text-align: right;
  margin-right: 2px;
  color: #444;
  width: 0;
}
